
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Application CSS */

.light-background-color {
  background: #F1F6FC
}

.no-padding {
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-left .dateInput input {
  -webkit-padding-end: 0px;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.space-margin-left {
  margin-left: 10px;
}

.space-margin-right {
  margin-right: 10px;
}

.space-margin-top {
  margin-top: 10px;
}

.space-margin-bottom {
  margin-bottom: 10px;
}

.space-padding-left {
  padding-left: 10px;
}

.space-padding-right {
  padding-right: 10px;
}

.space-padding-top {
  padding-top: 10px;
}

.space-padding-bottom {
  padding-bottom: 10px;
}

.space15-padding-bottom {
  padding-bottom: 15px;
}

.space15-padding-top {
  padding-top: 15px;
}

.light-background-color {
  /* background: red */
  background: #F1F6FC
}

.queue-style>div>div {
  z-index: 9999999;
}

/* wdr-empty */
.wdr-toolbar-group-right, .wdr-sheet-header, .wdr-wrapper, .wdr-resize-handles, .wdr-sheet-selection-header, #wdr-sheet-headers, .wdr-sheet-selection-canvas {
  display: none !important;
}

#wdr-data-sheet, #wdr-rows-sheet {
  left: 0px !important;
  top: 30px !important
}

#wdr-cols-sheet {
  left: 0px !important;
}

.wdr-filter-header {
  top: 0 !important;
}

/* .wdr-sheet-canvas{
  left :0 !important;
} */

.wd-profile-name {
  font-size: 12px !important;
  font-weight: 700 !important;
  margin-right: 4px !important;
}

.wd-profile-age {
  margin-right: 6px !important;
  padding-right: 6px !important;
}
.wd-profile-sideLineage {
  margin-right: 6px !important;
  padding-right: 6px !important;
  border-right: 1px solid !important;
}

.wd-profile-parent{
  margin-bottom: 4px !important;
}

.wd-profile-mrn{
  margin-right: 10px !important;
}

:root {
  --fc-event-bg-color: #ffffff;
  --fc-event-border-color: #ffffff;
}
.fc .fc-col-header-cell-cushion{
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #282c34 !important;
}
.react-calendar__tile--active, .react-calendar__tile--hasActive{
  background-color: #d8e9f7 !important;
  color: black !important;
}
.react-calendar__month-view__days__day--weekend{
  color: black !important;
}
.react-calendar__tile--now {
  color:blue !important;
  font-weight: bold !important;
  background:  #ebf1f7 !important;
}
.fc .fc-toolbar-title{
  font-size: larger !important;
  margin: 0px 10px !important;
  font-weight: 700 !important;
}
abbr[title]{
  text-decoration: none !important;
}
.fc .fc-toolbar.fc-header-toolbar{
  margin-bottom: 12px !important;
}

.react-calendar {
  line-height: 1em !important;
}
.react-calendar__navigation{
  margin-bottom: 0.5em !important;
}

.react-calendar__month-view__weekdays__weekday{
  color: #6b6e75 !important;
}
.notification-card {
    transition: opacity 0.7s ease-out, transform 0.7s ease-out;
    opacity: 1;
    transform: translateX(0); /* Initially, no horizontal movement */
    margin-bottom: 16px;
    overflow: hidden;
    background-color: white; /* Default background color */
  }
  
  .notification-card.slide-out {
    animation: slideOutAnimation 0.7s forwards; /* Trigger the animation */
  }
  
  @keyframes slideOutAnimation {
    0% {
      opacity: 1;
      transform: translateX(0); /* Start at the original position */
      background-color: white; /* Initial background color */
    }
    50% {
      opacity: 0.7; /* Slight fade in the middle */
      transform: translateX(50%); /* Slide to the right a bit */
      background-color: #f0f0f0; /* Light gray background */
    }
    100% {
      opacity: 0; /* Fully fade out */
      transform: translateX(100%); /* Move completely to the right */
      background-color: #ff6f61; /* Soft orange-red background */
    }
  }
  
.root {
	background-color: #fff;
	height: 100%;
	margin-left: 10px;
	position: relative;
	cursor: pointer;
}

.hiddenDiv {
  position:fixed;
  top: 64px;
  right: 0px;
  background-color:#fff;
  opacity: 1;
  -webkit-transform: translate3d(15px, 0, 0);
  transform: translate3d(15px, 0, 0);
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  width: 295px;
  box-shadow:20px 20px 50px grey; 
  z-index: 100;
  height: 100%;
  overflow: hidden;
}

.root:hover + .hiddenDiv {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.ticket {
	display: grid;
	grid-template-rows: auto 1fr auto;
	min-width: 20vw;
}
.ticket__header,
.ticket__body,
.ticket__footer {
	background-color: white;
	box-shadow: 0 2px 4px rgba(41, 54, 61, 0.25);
	border: 2px dashed #dce9f5;
}
.ticket__header {
	border-radius: 1.375em 1.375em 0 0;
	font-size: 0.89em;
	padding: 20px;
	font-weight: bold;
	border-bottom: none;
	box-shadow: none;
	padding-bottom: 0px;
}
.date {
	padding-bottom: 10px;
}
.pin_no {
	font-size: 0.89em;
	color: #6f6f6f;
	font-weight: 600;
	padding: 20px;
	padding-top: 0px;
}
.ticket__wrapper {
	box-shadow: 0 2px 4px rgba(41, 54, 61, 0.25);
	border-radius: 1.375em 1.375em 0 0;
	overflow: hidden;
	text-align: center;
}
.ticket__divider {
	position: relative;
	height: 1rem;
	background-color: white;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.ticket__divider::after {
	content: "";
	position: absolute;
	height: 50%;
	width: 100%;
	top: 0;
	border-bottom: 2px dashed #e9ebed;
}
.ticket__notch {
	position: absolute;
	left: -0.5rem;
	width: 1rem;
	height: 1rem;
	overflow: hidden;
}
.ticket__notch::after {
	content: "";
	position: relative;
	display: block;
	width: 2rem;
	height: 2rem;
	right: 100%;
	top: -50%;
	border: 0.5rem solid white;
	border-radius: 50%;
	box-shadow: inset 0 2px 4px rgba(41, 54, 61, 0.25);
}
.ticket__notch--right {
	left: auto;
	right: -0.5rem;
}
.ticket__notch--right::after {
	right: 0;
}
.ticket__body {
	border-top: none;
	border-radius: 0 0 1.375em 1.375em;
}
.ticket__body > * + * {
	border-top: 1px solid #dce9f5;
}
.p_1 {
	font-size: 15px;
	font-weight: bold;
	margin: 0;
	margin-bottom: 6px;
}
.p_2 {
	font-size: 14px;
	margin: 0;
	margin-bottom: 6px;
}
.p_3 {
	font-size: 14px;
	margin: 0;
	margin-bottom: 6px;
}

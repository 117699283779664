.dropzone {
  cursor: pointer;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%233341' stroke-width='4' stroke-dasharray='6%2c 17' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e");
}


.rootWaiting{
  padding: "10px",
}
.rootWaiting .carousel-root{
  background-color: #F3EEFC;
  margin: 16px 6px 6px;
  border-radius: 8px;
}
.rootWaiting .carousel .control-dots {
  text-align: center !important;
}
.rootWaiting .carousel .slide img{
  width: 30% !important;
  padding-top: 30px !important;
}
.rootWaiting .carousel .slide .legend {
  transition: all .5s ease-in-out;
  position: relative !important;
  top: 10px !important;
  background: none !important;
  color: #000 !important;
  padding: 10px;
  font-size: 12px;
  text-align: center !important;
  opacity: 1 !important;
  transition: opacity .35s ease-in-out;
}
.rootWaiting .carousel.carousel-slider{
  z-index: 1 !important;
  max-height: 370px;
  height: 370px;
}

.pi-root {
    padding: 0px 4px 0px 4px;
}

.p-dashed-divider {
    border: 0.5px dashed #fdfbfb !important;
}

.pi-paper {
    margin: 6px 0px !important;
    padding: 6px 16px;
    border-radius: 8px !important;
    background: white;
    box-shadow: 0px 10px 25px #0000000a !important;
    height: 100% !important;
}

.pi-title {
    font-size: 13px !important;
    font-weight: 600 !important;
    display: inline-block;
    color: #001C3C;
    font-family: 'pc_semibold' !important;
}

.pi-addbtn {
    float: right;
    color: #2A60BC;
    cursor: pointer;
    font-size: 14px !important;
    /* font-weight: 600 !important; */
    font-family: 'pc_medium' !important;
}

.pi-addbtn1 {
    /* float: right; */
    color: #2A60BC;
    cursor: pointer;
    font-size: 14px !important;
    font-family: 'pc_medium' !important;
    /* font-weight: 600 !important; */
}

.pi-actions-root {
    padding-top: 6px;
    padding-bottom: 10px;
}

.pi-names-root {
    padding-bottom: 11px;
    display: grid !important;
    grid-template-columns: 11% 11% 19% 19% 19% 11% 6%;
}

.pi-basic-details {
    display: grid !important;
    grid-template-columns: 18% 16% 9% 14% 9% 17% 17%;
}

.pi-upload-root {
    display: flex;
}

.pi-upload-root>label {
    box-shadow: unset;
    margin: auto;
    margin-bottom: 5px;
    margin-left: 2px;
}

.pn-row-root {
    grid-template-columns: 180px 1fr;
    display: grid !important;
}

.pn-image-root {
    padding: 6px 0px !important;
}

.pn-image-paper-root {
    padding: 0px;
    margin: 0px !important;
    height: 98% !important;
}

.pn-image-paper-root>div {
    /* padding: 8px; */
}

.pn-names-root {
    padding: 0 0 14px 14px;
}

.pn-names-actions {
    display: flex;
}

.pn-names-actions>div {
    margin: auto;
    margin-bottom: 12px;
}

.pn-names-actions>div>span {
    font-size: 12px !important;
    padding-right: 10px;
}

.pn-names-actions>div>i {
    font-size: 15px !important;
    padding-right: 10px;
}

.pn-image {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 10px !important;
    height: 178px !important;
    background-color: #F1F6FC !important;
}

.pn-upload-actions {
    padding: 10px 10px 10px;
    border-top: 1px solid #E0E0E0;
}

.pn-upload-actions>label {
    border: 1px solid lightgray;
    color: lightgray;
    font-weight: bold;
    width: 100%;
}

.pn-upload-actions>label>p {
    color: #7b7b7b;
}

.pn-upload-actions-btn {
    width: 100%;
    background-color: unset !important;
    color: black !important;
    border-color: #B6B6B6 !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    color: #6F6F6F !important;
    font-family: 'pc_medium' !important;
}

.aa-actions-btn-root {
    text-align: right !important;
    padding-top: 15px !important;
    padding-bottom: 20px !important;
}

.aa-actions-btn {
    margin-right: 15px;
}

.p-continue-action {
    text-align: center;
    padding: 20px 20px 44px 20px;
}

.sl-root {
    border: 1px solid #DEE5EC;
    border-radius: 8px;
    background: #F1F6FC;
}

.sl-detail-root {
    display: grid !important;
    grid-template-columns: 38px 1fr;
    padding: 0;
}

.sl-count {
    display: flex;
}

.sl-count>span {
    margin: auto;
    margin-left: 0;
    padding: 6px 10px 6px 7px;
    background: #DEE5EC;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    /* border-radius: 8px 0px 0px 8px; */
}

.sl-details {
    padding: 10px 15px 10px 0px;
    display: grid !important;
    grid-template-columns: 45px 1fr;
}

.sl-detail {
    padding: 10px 15px 10px 0px;
}

.sl-details-avatar {
    width: 35px !important;
    height: 35px !important;
    margin: 0 !important;
}

.sl-details-names {
    display: flex;
    flex-direction: column;
}

.sl-details-names>h6 {
    font-size: 14px;
    margin: 0;
    margin-bottom: 3px;
    line-height: 1;
    font-family: 'pc_medium' !important;
}

.sl-details-names>span {
    font-size: 12px;
    font-family: 'pc_medium' !important;
}

.sl-action-root {
    display: flex;
}

.sl-action-root>div {
    margin: auto;
    margin-right: 0px;
}

.sl-action-btn {
    font-size: 18px !important;
    margin-right: 15px;
    cursor: pointer;
}

.pa-showList {
    padding-top: 15px;
}

.fUWbfp, .gnnbCe, .default-size {
    font-size: 12px !important;
    color: #6F6F6F !important;
    margin: 0px !important;
    margin-bottom: 6px !important;
}

.ad-arrow {
    border-radius: 50%;
    border: 1px solid;
    padding: 2px 5px 3px 5px;
    color: black;
    font-weight: bold !important;
    margin-left: 11px;
}

.ad-tick {
    border-radius: 50%;
    border: 1px solid;
    padding: 4px 5px 4px 5px;
    color: white;
    background-color: #0080009c;
    /* font-weight: bold !important; */
    margin-left: 11px;
}

.pi-upload-doc {
    display: grid;
    grid-template-columns: 24px 1fr 36px 13px;
    width: 247px;
    border: 1px solid #a7a4a4;
    border-radius: 7px;
    background: #f3f3f33d;
    height: 42px;
    padding: 9px;
    margin-top: 20px;
}

.pi-upload-doc-img {
    height: 20px !important;
    width: 20px !important;
}

.pi-upload-doc-name {}

.pi-upload-doc-size {}

.pi-upload-doc-close {
    margin-top: 3px;
    cursor: pointer;
}

.pi-dot{
    width: 6;
    height: 6;
    background-color:"#38C20A";
    border-radius: '50%';
    margin: '0 5px'; 
}

.titleDiv{
    display: flex;
    justify-content: space-between;
}

.actionDiv{
    text-align: right !important;
}

.titleAddress{
    font-size: 17px;
    font-weight: 500;
    color: #001C3C;
}
.fc-v-event {
    background-color: unset !important; /* Removes the background color */
  }
/* Custom CSS for the now indicator */
.fc .fc-now-indicator {
  background-color: white;
  height: 2px; /* Adjust the height to make it a small line */
  z-index: 10; /* Ensure it appears on top */
}
/* Change current day's background color */
.fc-day-today {
  background-color: white !important; /* Replace with your desired color */
}

/* Change selected cells' background color */
.fc-highlight {
  background-color: #80cbc4 !important;
}
